export const useI18nAmount = () => {
  const getAmountVariant = (amount: number) => {
    const slicedAmount = parseInt(amount.toString().slice(-2));
    if (slicedAmount >= 11 && slicedAmount <= 14) {
      return 'five';
    }

    const remainder = amount % 10;
    if (remainder === 1) {
      return 'one';
    } else if (remainder >= 2 && remainder <= 4) {
      return 'two';
    } else {
      return 'five';
    }
  };

  return { getAmountVariant };
};
