export const messages = {
  end_chat: {
    question: 'Are you sure you want to end this chat?',
    success: 'The chat was successfully ended',
    error: 'Cannot end the chat. Error: {error}',
  },

  save_note: {
    success: 'The note was successfully saved',
    error: 'Cannot save the note. Error: {error}',
  },

  redirect: {
    question: 'What is the reason of redirecting this chat?',
    success: 'The chat was successfully redirected',
    error: 'Cannot redirect the chat. Error: {error}',
  },

  password_change: {
    success: 'The password was successfully changed',
    error: 'Cannot change the password. Error: {error}',
  },

  save_changes: {
    success: 'The changes were successfully saved',
    error: 'Cannot save changes. Error: {error}',
  },

  delete_chat: {
    question: 'Are you sure you want to delete this chat?',
    success: 'The chat was successfully deleted',
    error: 'Cannot delete the chat. Error: {error}',
  },

  delete_several_chats: {
    question: 'Do you want to delete group chats <span class="info">{names}</span> and all messages for all members?',
    success: 'Chats {names} was successfully deleted',
    error: 'Cannot delete chats. Error: {error}',
  },

  leave_group: {
    question: 'Are you sure you want to leave the group?',
    success: 'The group was successfully left',
    error: 'Cannot leave the group. Error: {error}',
  },

  delete_group: {
    title: 'Delete for all',
    subtitle: 'Do you want to delete the group chat and all of its messages for all members?',
    success: 'The group was successfully deleted',
    error: 'Cannot delete the group. Error: {error}',
  },

  save_file: {
    success: 'The file was successfully saved',
    error: 'Cannot save the file. Error: {error}',
  },

  delete_media_image: {
    question: 'Are you sure you want to delete this photo?',
    success: 'The photo was successfully deleted',
    error: 'Cannot delete the photo. Error: {error}',
  },

  delete_media_video: {
    question: 'Are you sure you want to delete this video?',
    success: 'The video was successfully deleted',
    error: 'Cannot delete the video. Error: {error}',
  },

  update_operator: {
    error: 'Cannot update operator info. Error: {error}',
  },

  switch: {
    question: 'Are you sure you want to lead this chat?',
    success: 'Success. Now you are leading this chat',
    error: 'Cannot switch this chat. Error: {error}',
  },

  delete_message: {
    question: 'Are you sure you want to delete this message?',
    success: 'The message was successfully deleted',
    error: 'Cannot delete this message. Error: {error}',
  },

  quick_answer: {
    success: 'The answer was successfully created',
    error: 'Cannot create a quick answer. Error: {error}',
  },

  delete_quick_answer: {
    question: 'Are you sure you want to delete this answer?',
    success: 'The answer was successfully deleted',
    error: 'Cannot delete this quick answer. Error: {error}',
  },

  create_chat: {
    error: 'Cannot create chat. Error: {error}',
  },

  edit_message: {
    error: 'Cannot edit message. Error: {error}',
  },

  forward: {
    success: 'The message was successfully forwarded to',
    error: 'Cannot forward the message. Error: {error}',
  },

  forgot_pass: {
    success: 'Confirmation email was sent. Check you mailbox',
  }
};
