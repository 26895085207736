import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { Chat, ChatType, Log, UnreadAmount } from '@/types/Chat';
import { computed, ref } from 'vue';
import { request } from '@/stores/request';
import { Message } from '@/types/Message';

export const useChatStore = defineStore('chatStore', () => {
  const { getErrorMessage } = useApi();

  const chat = ref<Chat>({} as Chat);
  const chats = ref<Chat[]>([]);
  const teamChats = ref<Chat[]>([]);
  const selectedChats = ref<number[]>([]);
  const selectedChatType = ref<string>('');
  const idleChats = ref<Chat[]>([]);
  const pendingChats = ref<Chat[]>([]);
  const switchResult = ref(false);
  const createdChatId = ref(0);
  const unreadAmount = ref<UnreadAmount>({ client: 0, operator: 0 });
  const error = ref('');

  const totalUnreadAmount = computed(() => {
    if (chats.value?.length) {
      return chats.value.map(chat => chat.unreadAmount ?? 0)?.reduce((prev, curr) => prev + curr);
    } else {
      return 0;
    }
  });

  const activeMembers = computed(() => chat.value.members?.filter(item => !item.leftAt) ?? []);

  const clientChats = computed((): Chat[] => chats.value.concat(pendingChats.value).concat(idleChats.value));

  const chatLog = computed((): Log[] => [...(chat.value?.log ?? [])].sort((a, b) => b.id - a.id));

  const getChat = async (chatId: number) => {
    try {
      chat.value = await request({ url: `chat/${ chatId }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getClientChats = async () => {
    try {
      chats.value = await request({ url: `chat/client`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getTeamChats = async () => {
    try {
      teamChats.value = await request({ url: `chat/operator`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getIdleChats = async () => {
    try {
      const chatList: Chat[] = await request({ url: `chat/idle`, method: 'get' });
      idleChats.value = chatList.map(item => ({ ...item, waitType: 'idle' }));
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getPendingChats = async () => {
    try {
      const chatList: Chat[] = await request({ url: `chat/pending`, method: 'get' });
      pendingChats.value = chatList.map(item => ({ ...item, waitType: 'pending' }));
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const switchChatOperator = async (chatId: number, reason: string) => {
    try {
      const response = await request({
        url: `chat/to-pending/${ chatId }`,
        method: 'post',
        data: { reason },
      });
      if (response?.chatId) {
        await getClientChats();
        await getIdleChats();
        switchResult.value = true;
      } else {
        switchResult.value = false;
      }
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const takeChatOperator = async (chatId: number) => {
    try {
      const response = await request({ url: `chat/take/${ chatId }`, method: 'get' });
      if (response?.chatId) {
        await getClientChats();
        await getIdleChats();
        switchResult.value = true;
      } else {
        switchResult.value = false;
      }
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const endChat = async (chatId: number, isTeam: boolean) => {
    try {
      await request({ url: `chat/end/${ chatId }`, method: 'get' });
      setChatActiveState(chatId, false, isTeam);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const leaveChat = async (chatId: number) => {
    try {
      await request({ url: `chat/leave/${ chatId }`, method: 'get' });
      setChatActiveState(chatId, false, true);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const addChatNote = async (chatId: number, note: string) => {
    try {
      await request({ url: `chat/${ chatId }/note`, method: 'post', data: { note } });
      await getChat(chatId);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const createTeamChat = async (data: { operatorId?: number; members?: number[]; name?: string; }) => {
    try {
      const response = await request({ url: `chat/start`, method: 'post', data });
      createdChatId.value = response?.id;
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const editTeamChat = async (chatId: number, name: string) => {
    try {
      await request({
        url: `chat/${ chatId }/edit`,
        method: 'post',
        data: { name },
      });
      await getChat(chatId);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const addTeamChatMembers = async (chatId: number, members: number[]) => {
    try {
      await request({
        url: `chat/add-operators/${ chatId }`,
        method: 'post',
        data: { members },
      });
      await getChat(chatId);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const deleteTeamChatMembers = async (chatId: number, members: number[]) => {
    try {
      await request({
        url: `chat/kick-operators/${ chatId }`,
        method: 'post',
        data: { members },
      });
      await getChat(chatId);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getChatsUnreadAmount = async () => {
    try {
      unreadAmount.value = await request({ url: `chat/unread`, method: 'get' });
      console.log(unreadAmount.value);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const setChatActiveState = (chatId: number, isActive: boolean, isTeam: boolean) => {
    const chatItem = (isTeam ? teamChats.value : chats.value).find((item: Chat) => item.id === chatId);
    if (chatItem) {
      chatItem.isActive = isActive;
    }
    if (chat.value.id === chatId) {
      chat.value.isActive = isActive;
    }
  };

  const setChatLastMessage = (message: Message, chatType: ChatType) => {
    const chatList = getChatList(chatType);
    const chat = chatList.find((item: Chat) => item.id === message.chatId);
    if (chat) {
      chat.lastMessage = message;
      chat.lastMessageId = message.id;
    }

    chats.value.sort((a, b) => b.lastMessageId - a.lastMessageId);
  };

  const setChatUnreadAmount = async (chatId: number, chatType: ChatType, amount: number) => {
    const chatList = getChatList(chatType);
    const chat = chatList.find((item: Chat) => item.id === chatId);
    if (chat?.id) {
      chat.unreadAmount = amount;
      if (chatList.length) {
        unreadAmount.value[chatType === 'operator' ? 'operator' : 'client'] =
          chatList
            .map(chat => chat.unreadAmount ?? 0)
            .reduce((prev, curr) => prev + curr);
      }
    } else {
      await getChatsUnreadAmount();
    }
  };

  const changeChatUnreadAmount = (chatId: number, chatType: ChatType, difference: number) => {
    const chatList = getChatList(chatType);
    const chat = chatList.find((item: Chat) => item.id === chatId);
    if (chat) {
      chat.unreadAmount += difference;
    }
  };

  const setChatLastMessageText = (chatId: number, chatType: ChatType, text: string) => {
    const chatList = getChatList(chatType);
    const chat = chatList.find((item: Chat) => item.id === chatId);
    if (chat && chat.lastMessage) {
      chat.lastMessage.text = text;
    }
  };

  const getChatList = (chatType: ChatType): Chat[] => {
    return (chatType === 'client' ? clientChats.value : teamChats.value) ?? [];
  };

  return {
    chat, chats, teamChats, selectedChats, idleChats, pendingChats, switchResult, createdChatId, error, unreadAmount,
    selectedChatType,
    totalUnreadAmount, activeMembers, chatLog,
    setChatActiveState, setChatLastMessage, setChatUnreadAmount, changeChatUnreadAmount, setChatLastMessageText,
    getChat, getClientChats, getTeamChats, getIdleChats, getPendingChats, switchChatOperator, takeChatOperator, endChat,
    addChatNote, createTeamChat, editTeamChat, addTeamChatMembers, deleteTeamChatMembers, getChatsUnreadAmount,
    leaveChat,
  };
});
