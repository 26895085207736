import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { request } from '@/stores/request';
import { Message } from '@/types/Message';
import { ref } from 'vue';
import { useChatStore } from '@/stores/chatStore';

export const useMessageStore = defineStore('messageStore', () => {
  const chatStore = useChatStore();
  const { getErrorMessage } = useApi();

  const messages = ref<Message[]>([]);
  const media = ref<Message[]>([]);
  const isSelectMode = ref(false);
  const selectedMessages = ref<number[]>([]);
  const error = ref('');

  const getMessages = async (chatId: number) => {
    try {
      const response = await request({ url: `message/${ chatId }`, method: 'get' });
      response.map((item: Message) => {
        if (item.attachments) {
          item.attachments = JSON.parse(JSON.stringify(item.attachments));
        }
      });
      messages.value = getFormattedMessages(response);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getMedia = async (chatId: number) => {
    try {
      const response = await request({ url: `message/media/${ chatId }`, method: 'get' });
      response.map((item: Message) => {
        if (item.attachments) {
          item.attachments = JSON.parse(JSON.stringify(item.attachments));
        }
      });
      media.value = response;
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const setReadMessages = async (messages: number[]) => {
    try {
      await request({ url: 'message/read', method: 'post', data: { messages } });
      await chatStore.getChatsUnreadAmount();
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const addNewMessage = (message: Message) => {
    messages.value = getFormattedMessages([message, ...messages.value]);
  }

  const addMedia = (value: Message[]) => media.value = [...media.value, ...value];

  const setMediaSrc = (data: { messageId: number; mediaId: number; src: string; }) => {
    const { messageId, mediaId, src } = data;
    const mediaItem = media.value.find(item => item.id === messageId);
    if (mediaItem) {
      const attachment = mediaItem?.attachments?.find(item => item.id === mediaId);
      if (attachment) {
        attachment.loadedSrc = src;
      }
    }

    const messageItem = messages.value.find(item => item.id === messageId);
    if (messageItem) {
      const attachment = messageItem?.attachments?.find(item => item.id === mediaId);
      if (attachment) {
        attachment.loadedSrc = src;
      }
    }
  };

  const $reset = () => {
    selectedMessages.value = [];
    isSelectMode.value = false;
  };

  return {
    messages, media, error, isSelectMode, selectedMessages,
    addNewMessage, addMedia, setMediaSrc,
    getMessages, getMedia, setReadMessages, $reset,
  };
});

const getFormattedMessages = (messages: Message[]): Message[] => {
  const formattedMessages: Message[] = [];

  for (let i = messages.length - 1; i >= 0; i--) {
    const m = messages[i];
    const item = formattedMessages[formattedMessages.length - 1];
    if (formattedMessages.length > 0 && +item.tgMessageId > 0 && +item?.tgMessageId === +m.tgMessageId) {
      if (m.attachments?.length) {
        item.attachments.push(...m.attachments);
      }

      if (m.text) {
        item.text += m.text;
      }

      if (item.reply === null && m.reply) {
        item.reply = m.reply;
      }
    } else {
      formattedMessages.push(m);
    }
  }

  return formattedMessages.reverse();
};
