import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ChatListView from '@/views/ChatListView.vue';
import LoginView from '@/views/LoginView.vue';
import SettingsView from '@/views/Settings/SettingsView.vue';
import ProfileView from '@/views/ProfileView.vue';
import ChatView from '@/views/ChatView.vue';
import NotificationsSettingsView from '@/views/Settings/NotificationsSettingsView.vue';
import RepliesSettingsView from '@/views/Settings/RepliesSettingsView.vue';
import GeneralSettingsView from '@/views/Settings/GeneralSettingsView.vue';
import PasswordSettingsView from '@/views/Settings/PasswordSettingsView.vue';
import RepliesListView from '@/views/Settings/RepliesListView.vue';
import ReplyFormView from '@/views/Settings/ReplyFormView.vue';
import AppearanceSettingsView from '@/views/Settings/AppearanceSettingsView.vue';
import TeamChat from '@/views/TeamChat/TeamChat.vue';
import PageNotFoundView from '@/views/PageNotFoundView.vue';
import ResetPasswordView from '@/views/ResetPasswordView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/chats',
  },
  {
    path: '/chats',
    name: 'chats',
    component: ChatListView,
  },
  {
    path: '/chats/:chatId',
    name: 'chat',
    component: ChatView,
  },
  {
    path: '/settings',
    children: [
      {
        path: '',
        name: 'settings',
        component: SettingsView
      },
      {
        path: 'replies',
        children: [
          {
            path: '',
            name: 'settings-replies',
            component: RepliesSettingsView,
          },
          {
            path: 'list',
            name: 'settings-replies-list',
            component: RepliesListView,
          },
          {
            path: 'form',
            name: 'settings-replies-form',
            component: ReplyFormView,
          },
        ]
      },
      {
        path: 'notifications',
        name: 'settings-notifications',
        component: NotificationsSettingsView,
      },
      {
        path: 'general',
        children: [
          {
            path: '',
            name: 'settings-general',
            component: GeneralSettingsView,
          },
          {
            path: 'password',
            name: 'settings-general-password',
            component: PasswordSettingsView,
          },
          {
            path: 'appearance',
            name: 'settings-general-appearance',
            component: AppearanceSettingsView,
          },
        ]
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            name: 'team-chat',
            component: TeamChat,
          },
          {
            path: ':chatId',
            name: 'team-chat-panel',
            component: ChatView,
          },
        ]
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPasswordView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFoundView',
    component: PageNotFoundView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
