import { useI18n } from 'vue-i18n';

export const useDatetime = () => {
  const i18n = useI18n();

  const getTwoNumberedValue = (value: string | number) => `0${ value }`.slice(-2);

  const getTimeStr = (date: Date | string) => {
    date = new Date(date);
    return `${ getTwoNumberedValue(date.getHours()) }:${ getTwoNumberedValue(date.getMinutes()) }`;
  };

  const getDateStr = (date: Date | string, separator = '.') => {
    date = new Date(date);

    return [
      getTwoNumberedValue(date.getDate()),
      getTwoNumberedValue(date.getMonth() + 1),
      date.getFullYear(),
    ].join(separator);
  };

  const getDateWithToday = (date: Date | string) => {
    const todayDate = getDateStr(new Date());
    const datetime = getDateStr(date);
    return datetime === todayDate ? i18n.t('today') : datetime;
  };

  const getDateTimeStr = (date: Date | string, separator = ' ') => `${ getDateStr(date) }${ separator }${ getTimeStr(date) }`;

  const getTimeFromSeconds = (value: number) => {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;

    const values = [minutes, seconds];
    if (hours > 0) values.unshift(hours);

    return values.map(item => `0${ item }`.slice(-2)).join(':');
  };

  return { getTwoNumberedValue, getTimeStr, getDateStr, getTimeFromSeconds, getDateTimeStr, getDateWithToday };
};
