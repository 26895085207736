export const form = {
  write_message: 'Write a message',
  login: 'Login',
  enter_login: 'Enter your login or email',
  enter_password: 'Enter password',
  old_password: 'Old password',
  new_password: 'New password',
  confirm_new_password: 'Confirm new password',
  password_hint: 'Your password should contain at least 6 characters, including 1 number.',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  email_hint: 'The instructions for password reset will be sent to your email',
  enter_email: 'Enter your email',
  choose_group: 'Choose a group',
  enter_group_name: 'Enter group name',
  group_name: 'Group name',
  enter_reply: 'Enter quick reply',
  type_message: 'Type message',
  name_group: 'Name your group',
  enter_name: 'Enter name',
  role: 'Role'
};
