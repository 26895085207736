export const settings = {
  notifications: 'Notifications',
  general: 'General',
  team_chat: 'Team chat',
  chat_messages: 'Chat messages sound',
  system_messages: 'System messages',
  warnings: 'Warnings',
  show_previews: 'Show previews',
  set_auto: 'Set automatically',
  time_zone: 'Time zone',
  replies_list: 'Quick replies list',
  replies_add: 'Add quick reply',
  appearance: 'Appearance',
  name_color: 'Your name color',
  name_color_text: 'Yor name and replies to your messages will be shown in the selected color.',
  name_color_hint: 'This color will be used for your name and he replies to your messages.',
};
