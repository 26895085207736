export const form = {
  write_message: 'Напишіть повідомлення',
  login: 'Логін',
  enter_login: 'Введіть логін або електронну пошту',
  enter_password: 'Введіть пароль',
  old_password: 'Старий пароль',
  new_password: 'Новий пароль',
  confirm_new_password: 'Підтвердіть новий пароль',
  password_hint: 'Ваш пароль має містити як мінімум 6 символів, включаючи одну цифру',
  first_name: 'Ім\'я',
  last_name: 'Прізвище',
  email: 'Email',
  email_hint: 'На цю пошту буде надіслано подальші інструкції для відновлення паролю',
  enter_email: 'Введіть Вашу електронну пошту',
  choose_group: 'Оберіть групу',
  enter_group_name: 'Введіть назву групи',
  group_name: 'Назва групи',
  enter_reply: 'Введіть текст швидкої відповіді',
  type_message: 'Введіть повідомлення',
  name_group: 'Назвіть Вашу групу',
  enter_name: 'Введіть ім\'я',
  role: 'Роль',
};
