import { settings } from '@/locales/ua/settings';
import { amount } from '@/locales/ua/amount';
import { form } from '@/locales/ua/form';
import { tools } from '@/locales/ua/tools';
import { buttons } from '@/locales/ua/buttons';
import { tabs } from '@/locales/ua/tabs';
import { messages } from '@/locales/ua/messages';

export const ua = {
  language: 'Мова',
  chats: 'Чати',
  search: 'Пошук',
  message: 'Повідомлення',
  unread_messages: 'Непрочитані повідомлення',
  logout: 'Вийти',
  auth: 'Авторизація',
  username: 'Логін',
  name: 'Ім\'я',
  password: 'Пароль',
  chat_finished: 'Чат завершено',
  chat_switched: 'Чат переадресовано іншому оператору',
  copied: 'Скопійовано',
  attachment: 'Вкладення',
  active: 'Активні',
  inactive: 'Неактивні',
  your_chat: 'Розпочався Ваш чат',
  chat_operator: 'Чат оператора {name}',
  operator: 'Оператор',
  quick_reply_group: 'Група швидких відповідей',
  new_folder_empty: 'Ця папка пуста..\nНові чати будуть з\'являтись тут!',
  folder_empty: 'Ця папка пуста..\nЖоден чат не належить до цієї папки',
  chat_empty: 'Тут поки немає повідомлень..\nВідправте повідомлення щоб розпочати чат!',
  chat_history: 'Історія чату',
  date: 'Дата',
  user: 'Користувач',
  redirect_reason: 'Причина переадресації',
  note: 'Нотатка',
  general_info: 'Основна інформація',
  user_name: 'Ім\'я користувача',
  phone_number: 'Номер телефону',
  leave_note: 'Залишити нотатку',
  write_note: 'Напишіть нотатку',
  send_to: 'Надіслати до',
  forwarded_from: 'Переслано від',
  forgot_password: 'Забули пароль',
  saving: 'Збереження',
  reply_group: 'Група швидких відповідей',
  only_my_reply: 'Показувати лише власні',
  not_in_list: 'Не у списку',
  new_message: 'Нове повідомлення',
  new_group: 'Нова група',
  group_members: 'Учасники групи',
  select_chat: 'Оберіть чат',
  owner: 'Власник',
  sending: 'Відправка',
  processing: 'Обробка',
  today: 'Сьогодні',
  profile_info: 'Профіль',
  of: 'з',
  at: 'о',
  choose_members: 'Оберіть учасників',
  message_404: 'Ууууупс! Не вдалось знайти сторінку, яку ви шукали!',
  nickname: 'Нікнейм',
  first_name: 'Ім\'я',
  last_name: 'Прізвище',
  no_search_result: 'За Вашим запитом не знайдено жодного чату',
  no_quick_answers: 'Ще немає доданих швидких відповідей',
  waiting_amount: 'нових чатів очікують',
  wait_idle: 'очікує відповіді',
  wait_pending: 'переадресовано',
  too_big_size: 'Неможливо надіслати файл розміром більшим, ніж 50 MB',
  took_chat: 'забрав/ла чат',
  redirect_chat: 'переадресував/ла чат',
  took_your_chat: 'забрав/ла Ваш чат',

  themes: {
    light: 'Світла',
    dark: 'Темна',
  },

  attachments: {
    document: 'Файл',
    image: 'Зображення',
    location: 'Розташування',
    video: 'Відео',
    audio: 'Голосове повідомлення',
  },

  pin: {
    edit: 'Редагування повідомлення',
    reply: 'Відповідь до',
    forward: 'Пересилання повідомлення',
  },

  event: {
    new_user: 'Новий користувач',
    ended: 'Чат завершено',
    added_you: 'додав тебе до чату'
  },

  status: {
    text: 'Статус',
    online: 'Онлайн',
    offline: 'Офлайн',
  },

  roles: {
    operator: 'Оператор',
    admin: 'Адміністратор',
  },

  months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],

  settings,
  form,
  tools,
  tabs,
  buttons,
  ...amount,
  ...messages,
};
