export const tabs = {
  //stat: 'Статистика',
  stat: 'Аналіз',
  profile: 'Профіль',
  chats: 'Чати',
  //settings: 'Налаштування', ?? Сетап Лаштунки
  settings: 'Опції',
  image: 'Фото',
  video: 'Відео',
  media: 'Медіа',
  document: 'Документ',
  audio: 'Аудіо',
  contacts: 'Контакти',
  link: 'Лінки',
  client: 'Клієнти',
  operator: 'Оператори',

  chat: {
    new: 'Нові',
    main: 'Основні',
    done: 'Завершені',
  },
};
