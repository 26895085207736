export const amount = {
  files_attached: {
    one: '{amount} файл прикріплено',
    two: '{amount} файли прикріплено',
    five: '{amount} файлів прикріплено',
  },

  messages: {
    one: '{amount} повідомлення',
    two: '{amount} повідомлення',
    five: '{amount} повідомлень',
  },

  members: {
    one: '{amount} учасник',
    two: '{amount} учасники',
    five: '{amount} учасників',
  },
}
