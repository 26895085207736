interface Point {
  x: number;
  y: number;
}

export const vSlide = (el: HTMLElement, { value }: { value: (direction: string) => void }) => {
  if (typeof value !== 'function') {
    console.warn(`Expect a function, got ${ value }`);
    return;
  }

  let startPoint: Point;

  const start = (e: Event) => {
    startPoint = getPoint(e);
  };

  const move = (e: Event) => {
    if (startPoint?.x) {
      const diff = startPoint.x - getPoint(e).x;
      if (Math.abs(diff) > 100) {
        value(diff > 0 ? 'right' : 'left');
      }
    }
  };

  const cancel = () => {
    startPoint = {} as Point;
  };

  el.ontouchstart = start;
  el.onmousedown = start;

  el.ontouchmove = move;
  el.onmousemove = move;

  el.ontouchend = cancel;
  el.ontouchcancel = cancel;
  el.onmouseup = cancel;
};

const getPoint = (e: Event) => {
  if (e.type.includes('touch')) {
    const { touches } = e as TouchEvent;
    return { x: touches[0].clientX, y: touches[0].clientY };
  } else {
    const { clientX, clientY } = e as MouseEvent;
    return { x: clientX, y: clientY };
  }
};
