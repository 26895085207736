export const settings = {
  notifications: 'Сповіщення',
  general: 'Загальні',
  team_chat: 'Робочий чат',
  chat_messages: 'Звук повідомлень чату',
  system_messages: 'Системні повідомлення',
  warnings: 'Попередження',
  show_previews: 'Показувати прев\'ю',
  set_auto: 'Встановити автоматично',
  time_zone: 'Часовий пояс',
  replies_list: 'Список швидких відповідей',
  replies_add: 'Додати швидку відповідь',
  appearance: 'Вигляд',
  name_color: 'Ваш колір',
  name_color_text: 'Ваше ім\'я та відповіді на Ваші повідомлення будуть відображатись відповідно до обраного кольору.',
  name_color_hint: 'Цей колір буде використовуватись для відображення Вашого імені та відповідей на Ваші повідомлення.',
};
