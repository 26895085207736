import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { request } from '@/stores/request';
import { useApi } from '@/composable/useApi';
import { User, UserSettings } from '@/types/User';

export const useOperatorStore = defineStore('operatorStore', () => {
  const { getErrorMessage } = useApi();

  const operators = ref<User[]>([]);
  const operator = ref<User>({} as User);
  const error = ref('');

  const operatorId = computed(() => operator.value?.id);

  const login = async (data: { username: string; password: string; }) => {
    try {
      const response = await request({ url: 'auth/login', method: 'post', data });
      if (response?.id) {
        localStorage.setItem('jwt', response.token);
        operator.value = response;
        error.value = '';
      }
    } catch (err) {
      resetCurrentOperator();
      error.value = getErrorMessage(err);
    }
  };

  const logout = async () => {
    try {
      await request({ url: `auth/logout`, method: 'get' });
      resetCurrentOperator();
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await request({ url: `auth/forgot-password/${ email }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const resetPassword = async (data: { password: string; token: string; }) => {
    try {
      await request({ url: `auth/reset-password`, method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const changePassword = async (data: { password: string; oldPassword: string; }) => {
    try {
      const operatorData = await request({
        url: `auth/change-password`,
        method: 'post',
        data: { ...data, userId: operatorId.value }
      });
      operator.value = operatorData;
      localStorage.setItem('jwt', operatorData.token);
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getCurrentOperator = async () => {
    try {
      operator.value = await request({ url: `user/${ operatorId.value }`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const getOperators = async () => {
    try {
      operators.value = await request({ url: `user/role/operator`, method: 'get' });
      error.value = '';
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const updateOperator = async (operatorId: number, data: Partial<User>) => {
    try {
      const response = await request({ url: `user/${ operatorId }`, method: 'put', data });
      if (response?.id) {
        operator.value = response;
      }
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const updateOperatorSettings = async (operatorId: number, data: Partial<UserSettings>) => {
    try {
      const response = await request({ url: `user/${ operatorId }/settings`, method: 'put', data });
      console.log(response);
      if (response?.id) {
        operator.value = response;
      }
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const resetCurrentOperator = () => {
    operator.value = {} as User;
    localStorage.setItem('jwt', '');
  };

  return {
    operators, operator, error,
    operatorId,
    login, logout, getOperators, updateOperator, resetCurrentOperator, getCurrentOperator, forgotPassword,
    resetPassword, changePassword, updateOperatorSettings,
  };
}, {
  persist: true,
});
