import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useDatetime } from '@/composable/useDatetime';
import { Message, MessageFile } from '@/types/Message';
import { useMedia } from '@/composable/useMedia';
import { useMessageStore } from '@/stores/messageStore';

export const useAudio = (params: { message: Message, blob?: string }) => {
  const messageStore = useMessageStore();
  const { getTimeFromSeconds } = useDatetime();
  const { getAttachmentUrl, getMessageAttachment } = useMedia();
  const attachment = computed((): MessageFile => getMessageAttachment(params.message));

  const isPlaying = ref(false);
  const isLoaded = ref(false);
  const audio = ref();
  const currentTime = ref('');
  const currentPercent = ref(0);
  const audioDuration = ref(attachment.value?.duration ?? 0);
  const audioSrc = ref('');

  const onPlayClick = () => {
    if (!document.getElementsByClassName('tools')?.length) {
      if (isPlaying.value) {
        audio.value.pause();
      } else {
        document.dispatchEvent(new Event('start_playing'));
        audio.value.play();
        currentTime.value = '00:00';
      }
      isPlaying.value = !isPlaying.value;
    }
  };

  const onAudioEnded = () => {
    isPlaying.value = false;
    currentPercent.value = 0;
    setInitialCurrentTime();
  };

  const onTimeUpdate = () => {
    if (audio.value?.currentTime >= 0) {
      currentTime.value = getTimeFromSeconds(Math.floor(audio.value.currentTime));
      currentPercent.value = Math.min(audio.value.currentTime * 100 / (audioDuration.value > 0 ? audioDuration.value : 1), 98);
    }
  };

  const getDuration = async () =>
    new Promise((resolve) => {
      audio.value.addEventListener('loadedmetadata', () => resolve(audio.value?.duration ?? 0));
    });

  const stop = () => {
    if (isPlaying.value) {
      audio.value?.pause();
      audio.value.currentTime = 0;
      setTimeout(onAudioEnded, 200);
    }
  };

  const onDataLoad = () => {
    if (!attachment.value?.loadedSrc) {
      void messageStore.setMediaSrc({
        messageId: params.message.id,
        mediaId: attachment.value?.id,
        src: audio.value.getAttribute('src'),
      });
    }
    isLoaded.value = true;
  };

  const setInitialCurrentTime = () => currentTime.value = getTimeFromSeconds(Math.max(Math.floor(audioDuration.value), 1));

  onMounted(() => {
    audioSrc.value = params?.blob ?? getAttachmentUrl(attachment.value);

    audio.value.addEventListener('ended', onAudioEnded);
    audio.value.addEventListener('timeupdate', onTimeUpdate);
    audio.value.addEventListener('loadedmetadata', onDataLoad);

    document.addEventListener('start_playing', stop);

    currentTime.value = getTimeFromSeconds(Math.floor(attachment.value?.duration ?? 0));

    if (!audioDuration.value) {
      getDuration().then((value: unknown) => {

        if (value as number > 0 && value !== Infinity) {
          audioDuration.value = Math.max(Math.floor(value as number), 1);
        } else {
          audioDuration.value = 1;
        }
      });


      setInitialCurrentTime();
    } else {
      audioDuration.value = Math.max(audioDuration.value, 1);
    }
  });

  onBeforeUnmount(() => {
    stop();
    audio.value.removeEventListener('loadedmetadata', onDataLoad);
    document.removeEventListener('start_playing', stop);
  });

  return {
    isPlaying, isLoaded, audio, currentTime, currentPercent, audioDuration, audioSrc, attachment,
    onPlayClick, onAudioEnded, onTimeUpdate, getDuration, stop, setInitialCurrentTime,
  };
};
