<template>
  <div class="loader">
    <div class="loader__icon"></div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.loader {
  @extend .row-container;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__icon {
    @include icon($loader, 3rem);
    animation: rotate 1s infinite;
  }
}
</style>
