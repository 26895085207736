import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useOperatorStore } from '@/stores/operatorStore';
import { storeToRefs } from 'pinia';

export const useFirebase = () => {
  const operatorStore = useOperatorStore();
  const { operator } = storeToRefs(operatorStore);
  const { updateOperator } = operatorStore;

  /*const app = initializeApp({
    apiKey: "AIzaSyBCqI75zi0eCVdmH7531cfED0mU3YRaTy8",
    authDomain: "chat-notification-6a2c6.firebaseapp.com",
    projectId: "chat-notification-6a2c6",
    storageBucket: "chat-notification-6a2c6.appspot.com",
    messagingSenderId: "307789609096",
    appId: "1:307789609096:web:db13f1875f66d72507873d",
    measurementId: "G-PS9YDD744F"
  });*/

  initializeApp({
    apiKey: 'AIzaSyA_NgrAp14rJ-aMCEmVkrLny0iYQQXns9U',
    authDomain: 'test-chat-ed5c6.firebaseapp.com',
    projectId: 'test-chat-ed5c6',
    storageBucket: 'test-chat-ed5c6.appspot.com',
    messagingSenderId: '889275511232',
    appId: '1:889275511232:web:64a258ede61fe6ec049825',
  });

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
  });

  const setToken = () => {
    getToken(messaging, {
      //vapidKey: 'BPQ82iA6sPc1mqrWe4wrv_VaDIoz0Uj78KOCHG9LxotULQbHwOzARwHyqhPcGy8zQFI79bfP4H14hgOFZjMkofA'
      vapidKey: 'BHGaue41JmRW3L1_gWPwat19dxF2Xh2BR3pJ8Dsenk-6eF5kuhQpBh7JgeOShsmNfMO-n_CokJ2V0iQoI-KHDgo',
    }).then(async (currentToken) => {
      if (currentToken && operator.value?.id > 0) {
        // Send the token to your server and update the UI if necessary
        await updateOperator(operator.value.id, { messageToken: currentToken });
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  };

  return { setToken };
};

