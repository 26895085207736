import { createApp, h, render } from 'vue';
import NotificationPopup from '@/components/popup/NotificationPopup.vue';
import { useI18n } from 'vue-i18n';
import { Message } from '@/types/Message';
import NewMessagePopup from '@/components/popup/NewMessagePopup.vue';
import { useChatStore } from '@/stores/chatStore';
import { storeToRefs } from 'pinia';
import { SseStartChatData } from '@/types/Sse';
import NewEventPopup from '@/components/popup/NewEventPopup.vue';
import { useRouter } from 'vue-router';
import { Chat } from '@/types/Chat';

export const useRender = () => {
  const chatStore = useChatStore();
  const { chat, chats, teamChats } = storeToRefs(chatStore);
  const { push } = useRouter();
  const i18n = useI18n();

  const renderNotification = (text: string, isLocalized = false) => {
    const container = document.createElement('div');
    render(h(NotificationPopup, { text: `${ isLocalized ? text : i18n.t(text) }` }), container);
  };

  const renderNewMessage = async (message: Message, chatType: string, onClick: (m: Message, ct: string) => void) => {
    if (!document.getElementById(`message-${ message.id }`)) {
      let currentChat: Chat | undefined;
      if (chatType === 'client' && chats.value.length) {
        currentChat = chats.value.find(item => item.id === message.chatId);
      } else if (chatType === 'operator' && teamChats.value.length) {
        currentChat = teamChats.value.find(item => item.id === message.chatId);
      }

      if (!currentChat) {
        await chatStore.getChat(message.chatId);
        currentChat = chat.value;
      }

      if (!currentChat) return;

      const messagePopup = createApp({
        setup() {
          return () => h(NewMessagePopup, {
            chat: JSON.parse(JSON.stringify({
              ...currentChat,
              lastMessage: { ...message, text: message?.reply?.text ?? message.text },
            })),
            chatType,
            texts: {
              team_chat: i18n.t('settings.team_chat'),
            },
            onClick: () => onClick(message, chatType),
          });
        },
      });
      const wrapper = document.createElement('div');
      wrapper.setAttribute('id', `message-${ message.id }`);
      messagePopup.mount(wrapper);
      document.body.appendChild(wrapper);

      setTimeout(() => document.body.removeChild(wrapper), 5000);
    }
  };

  const renderNewEvent = (data: SseStartChatData) => {
    //if (!document.getElementById(`event-${ data.id }`)) {
    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', `event-${ data.id ?? data.chatId }`);

    let timeout = 0;

    const eventPopup = createApp({
      setup() {
        return () => h(NewEventPopup, {
          data,
          texts: {
            new_user: i18n.t('event.new_user'),
            ended: i18n.t('event.ended'),
            team_chat: i18n.t('settings.team_chat'),
            added: i18n.t('event.added_you'),
            operator: i18n.t('operator'),
            took_chat: i18n.t('took_your_chat')
          },
          onClick: () => {
            const el = document.getElementById(`event-popup-${ data.id ?? data.chatId }`);
            if (el) {
              el.classList.add('closing');
              clearTimeout(timeout);
              setTimeout(() => document.body.removeChild(wrapper), 1000);
            }

            if (data.type === 'new-start-chat' && data.chatType === 'client') {
              push('/chats');
            } else if (data.type === 'start-chat' && data.chatType === 'operator') {
              push('/settings/chat');
            }
          },
        });
      },
    });

    eventPopup.mount(wrapper);
    document.body.appendChild(wrapper);
    timeout = setTimeout(() => document.body.removeChild(wrapper), 50000);
    //}
  };

  return { renderNotification, renderNewMessage, renderNewEvent };
};
