import { settings } from '@/locales/en/settings';
import { amount } from '@/locales/en/amount';
import { form } from '@/locales/en/form';
import { tools } from '@/locales/en/tools';
import { buttons } from '@/locales/en/buttons';
import { tabs } from '@/locales/en/tabs';
import { messages } from '@/locales/en/messages';

export const en = {
  language: 'Language',
  chats: 'Chats',
  search: 'Search',
  message: 'Message',
  unread_messages: 'Unread messages',
  logout: 'Log out',
  auth: 'Authorization',
  username: 'Username',
  name: 'Name',
  password: 'Password',
  chat_finished: 'Chat finished',
  chat_switched: 'Chat forwarded to another operator',
  copied: 'Copied',
  attachment: 'Attachment',
  active: 'Active',
  inactive: 'Inactive',
  your_chat: 'Your chat started',
  chat_operator: '{name}\'s operator chat',
  operator: 'Operator',
  quick_reply_group: 'Quick Reply Group',
  new_folder_empty: 'Folder is empty..\nNew chats will soon appear here!',
  folder_empty: 'Folder is empty..\nNo chats currently belong to this folder',
  chat_empty: 'No messages here yet..\nSend a message to start a chat!',
  chat_history: 'Chat history',
  date: 'Date',
  user: 'User',
  redirect_reason: 'Redirect reason',
  note: 'Note',
  general_info: 'General information',
  user_name: 'User name',
  phone_number: 'Phone number',
  leave_note: 'Leave a note',
  write_note: 'Write a note',
  send_to: 'Send to',
  forwarded_from: 'Forwarded from',
  forgot_password: 'Forgot password',
  saving: 'Saving',
  reply_group: 'Quick reply group',
  only_my_reply: 'Show only personal added',
  not_in_list: 'Not in the list',
  new_message: 'New message',
  new_group: 'New group',
  group_members: 'Group members',
  select_chat: 'Select chat',
  owner: 'Owner',
  sending: 'Sending',
  processing: 'Processing',
  today: 'Today',
  profile_info: 'Profile information',
  of: 'of',
  at: 'at',
  choose_members: 'Choose members',
  message_404: 'Whoops! We couldn\'t connect to the page you were looking for!',
  nickname: 'Nickname',
  first_name: 'First name',
  last_name: 'Last name',
  no_search_result: 'No chats found',
  no_quick_answers: 'There are no quick answers yet',
  waiting_amount: 'new chats are waiting',
  wait_idle: 'waiting for answer',
  wait_pending: 'redirected',
  too_big_size: 'File cant be sent, the size is more than 50 MB',
  took_chat: 'took the chat',
  redirect_chat: 'redirected the chat',
  took_your_chat: 'took your chat',

  themes: {
    light: 'Light',
    dark: 'Dark',
  },

  attachments: {
    document: 'File',
    image: 'Photo',
    location: 'Location',
    video: 'Video',
    audio: 'Audio',
  },

  pin: {
    edit: 'Editing message',
    reply: 'Reply to',
    forward: 'Forward message',
  },

  event: {
    new_user: 'New user',
    ended: 'Chat ended',
    added_you: 'added you to the chat'
  },

  status: {
    text: 'Status',
    online: 'Online',
    offline: 'Offline',
  },

  roles: {
    operator: 'Operator',
    admin: 'Administrator',
  },

  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

  settings,
  form,
  tools,
  tabs,
  buttons,
  ...amount,
  ...messages,
};
