interface Point {
  x: number;
  y: number;
}

export const vLongPress = (el: HTMLElement, { value }: { value: (e: Event) => void }) => {
  if (typeof value !== 'function') {
    console.warn(`Expect a function, got ${ value }`)
    return;
  }

  let pressTimer: number | null = null;
  let startPoint: Point;
  let currentPoint: Point;

  const start = (e: Event) => {
    if (pressTimer === null) {
      startPoint = getPoint(e);
      currentPoint = {} as Point;
      pressTimer = setTimeout(() => {
        console.log(typeof currentPoint?.y, Math.abs(currentPoint.y - startPoint.y));
        if (typeof currentPoint?.y === 'undefined' || Math.abs(currentPoint.y - startPoint.y) < 20) {
          value(e);
        }
      }, 300);
    }
  }

  const move = (e: Event) => currentPoint = getPoint(e);

  const cancel = () => {
    if (pressTimer !== null) {
      clearTimeout(pressTimer);
      pressTimer = null;
      startPoint = {} as Point;
      currentPoint = {} as Point;
    }
  };


  el.ontouchstart = start;

  el.ontouchmove = move;

  el.ontouchend = cancel;
  el.ontouchcancel = cancel;
};

const getPoint = (e: Event) => {
  if (e.type.includes('touch')) {
    const { touches, changedTouches } = e as TouchEvent;
    const touch = touches?.length ? touches[0] : changedTouches[0];
    return { x: touch.clientX, y: touch.clientY };
  } else {
    const { clientX, clientY } = e as MouseEvent;
    return { x: clientX, y: clientY };
  }
};
