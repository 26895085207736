import { computed } from 'vue';
import { useOperatorStore } from '@/stores/operatorStore';
import { storeToRefs } from 'pinia';
import { useChatStore } from '@/stores/chatStore';
import { Chat } from '@/types/Chat';

export const useOperator = () => {
  const chatStore = useChatStore();
  const operatorStore = useOperatorStore();
  const { operatorId, operators } = storeToRefs(operatorStore);

  const isCurrentOperator = computed((): boolean => {
    const members = [...chatStore.chat.members].sort((a, b) => a.joinedAt > b.joinedAt ? -1 : 1);
    return members[0].operatorId === operatorId.value;
  });

  const getOperatorName = (id: number) => {
    const operator = operators.value.find(item => item.id === id);
    if (operator) {
      return operator?.name ?? operator?.username;
    } else {
      return '??';
    }
  };

  const getChatColor = (chat: Chat) => operators.value.find(item => item.id === chat?.ownerId)?.settings?.color ?? null;

  const getOperator = (operId: number | string) => operatorStore.operators.find(item => item.id === +operId);

  const getOperatorColor = (operId: number | string) => getOperator(operId)?.settings?.color ?? 'dark-blue';

  const getForwardOperatorColor = (operName: string) => {
    return operatorStore.operators.find(item => item.name === operName || item.username === operName)?.settings?.color ?? 'dark-blue';
  };

  return {
    operatorId, operators, isCurrentOperator,
    getChatColor, getOperatorName, getOperator, getOperatorColor, getForwardOperatorColor,
  };
};
