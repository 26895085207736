export const buttons = {
  login: 'Log in',
  open_location: 'Open location',
  continue: 'Continue',
  switch_operator: 'Change operator',
  end_chat: 'End chat',
  stay_in_chat: 'Stay in chat',
  submit: 'Submit',
  cancel: 'Cancel',
  back: 'Back',
  save_changes: 'Save changes',
  redirect: 'Redirect',
  select_all: 'Select all',
  save: 'Save',
  apply_color: 'Apply color',
  next: 'Next',
  create: 'Create',
  add_member: 'Add member',
  go_home: 'Go back to Home page',
  reset: 'Reset',
};
