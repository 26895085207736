export const tools = {
  edit: 'Edit',
  copy: 'Copy',
  download: 'Download',
  reply: 'Reply',
  forward: 'Forward',
  select: 'Select',
  delete: 'Delete',

  media: 'Media files',
  switch: 'Change operator',
  end: 'End chat',

  emoji: 'Emoji',
  replies: 'Quick replies',
  attach: 'Add file',

  delete_chat: 'Delete chat',
  delete_group: 'Delete group',
  leave_group: 'Leave group',

  show: 'Show in chat',
  save: 'Save file',
};
