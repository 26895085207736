import { defineStore } from 'pinia';
import { useApi } from '@/composable/useApi';
import { Message, MessageWithFile } from '@/types/Message';
import { request } from '@/stores/request';
import { ref } from 'vue';
import { useMessageStore } from '@/stores/messageStore';

type Modify<T, R> = Omit<T, keyof R> & R;
type ForwardMessageData = Partial<Modify<Message, {
  chatId: number | number[];
  messageId: number | number[];
  senderName: string | string[];
}>>;

interface ReplyMessageData extends Partial<Message> {
  replyId: number;
}

export const useMessageActionStore = defineStore('messageActionStore', () => {
  const messageStore = useMessageStore();
  const { getErrorMessage } = useApi();

  const editingMessage = ref<Message>({} as Message);
  const forwardMessage = ref<Message>({} as Message);
  const replyMessage = ref<Message>({} as Message);
  const error = ref('');
  const isSending = ref(false);

  const sendMessage = async (body: Partial<Message>) => {
    try {
      await request({ url: `chat/message/send`, method: 'post', data: body });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const sendMessageWithFiles = async (body: MessageWithFile) => {
    try {
      const { files, ...message } = body;
      const formData = new FormData();
      formData.append('message', JSON.stringify(message));
      for (let i = 0; i < body.files.length; i++) {
        const encodedFileName = encodeURIComponent(files[i].name);
        formData.append('files', files[i], encodedFileName);
      }
      await request({ url: 'chat/message/send-files', method: 'post', data: formData });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const editMessage = async (data: { chatId: number, messageId: number; text: string }) => {
    try {
      const response = await request({
        url: `chat/message/edit`,
        method: 'post',
        data,
      });
      if (response.id) {
        await messageStore.getMessages(data.chatId);
      }
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const forwardMessageTo = async (body: ForwardMessageData) => {
    try {
      await request({ url: `chat/message/forward`, method: 'post', data: body });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const replyToMessage = async (body: ReplyMessageData) => {
    try {
      await request({ url: `chat/message/reply`, method: 'post', data: body });
      replyMessage.value = {} as Message;
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const deleteMessage = async (messageId: number) => {
    try {
      await request({ url: `chat/message/delete/${ messageId }`, method: 'get' });
      const messageIndex = messageStore.messages.findIndex(message => message.id === messageId);
      if (messageIndex >= 0) {
        messageStore.messages.splice(messageIndex, 1);
      }

      const mediaIndex = messageStore.media.findIndex(message => message.id === messageId);
      if (mediaIndex >= 0) {
        messageStore.media.splice(mediaIndex, 1);
      }
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const $reset = () => {
    editingMessage.value = {} as Message;
    forwardMessage.value = {} as Message;
    replyMessage.value = {} as Message;
  };

  return {
    editingMessage, forwardMessage, replyMessage, error, isSending,
    sendMessage, sendMessageWithFiles, editMessage, forwardMessageTo, replyToMessage, deleteMessage, $reset,
  };
});
