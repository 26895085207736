export const amount = {
  files_attached: {
    one: '{amount} file attached',
    two: '{amount} files attached',
    five: '{amount} files attached',
  },

  messages: {
    one: '{amount} message',
    two: '{amount} messages',
    five: '{amount} messages',
  },

  members: {
    one: '{amount} member',
    two: '{amount} members',
    five: '{amount} members',
  },
}
