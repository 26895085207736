import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { QuickAnswer, QuickAnswerGroup } from '@/types/QuickAnswer';
import { request } from '@/stores/request';
import { useApi } from '@/composable/useApi';
import { SelectOption } from '@/types/Form';

export const useQuickAnswerStore = defineStore('quickAnswerStore', () => {
  const { getErrorMessage } = useApi();

  const quickAnswers = ref<QuickAnswerGroup[]>([]);
  const groupId = ref(0);
  const selectedGroup = ref(0);
  const error = ref('');

  const quickAnswersGroups = computed((): SelectOption[] => quickAnswers.value.map(item => ({
    label: item.name,
    value: item.id,
  })));

  const currentAnswers = computed((): QuickAnswer[] => {
    if (selectedGroup.value) {
      return quickAnswers.value.find(item => item.id === selectedGroup.value)?.answers ?? [];
    } else {
      const answers: QuickAnswer[] = [];
      quickAnswers.value.map(item => answers.push(...item.answers));
      return answers;
    }
  });

  const getQuickAnswers = async () => {
    try {
      quickAnswers.value = await request({ url: `quick-answer`, method: 'get' });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const createGroup = async (name: string) => {
    try {
      const response = await request({ url: `quick-answer/group`, method: 'post', data: { name } });
      groupId.value = response?.id;
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const createAnswer = async (data: { groupId: number; operatorId: number; text: string; }) => {
    try {
      await request({ url: `quick-answer`, method: 'post', data });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const updateAnswer = async (data: { answerId: number; text: string; }) => {
    try {
      await request({ url: `quick-answer/${ data.answerId }`, method: 'put', data: { text: data.text } });
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  const deleteAnswer = async (answerId: number) => {
    try {
      await request({ url: `quick-answer/${ answerId }`, method: 'delete' });
      await getQuickAnswers();
      error.value = '';
    } catch (err) {
      error.value = getErrorMessage(err);
    }
  };

  return {
    quickAnswers, groupId, error, selectedGroup,
    quickAnswersGroups, currentAnswers,
    getQuickAnswers, createGroup, createAnswer, updateAnswer, deleteAnswer,
  };
});
