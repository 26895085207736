export const tools = {
  edit: 'Редагувати',
  copy: 'Копіювати',
  download: 'Завантажити',
  reply: 'Відповісти',
  forward: 'Переслати',
  select: 'Вибрати',
  delete: 'Видалити',

  media: 'Медіафайли',
  switch: 'Змінити оператора',
  end: 'Завершити чат',

  emoji: 'Емоджі',
  replies: 'Швидкі відповіді',
  attach: 'Додати файл',

  delete_chat: 'Видалити чат',
  delete_group: 'Видалити групу',
  leave_group: 'Покинути групу',

  show: 'Показати в чаті',
  save: 'Зберегти файл',
};
