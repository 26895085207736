export const tabs = {
  stat: 'Analyse',
  profile: 'Profile',
  chats: 'Chats',
  settings: 'Settings',
  image: 'Image',
  video: 'Video',
  media: 'Media',
  document: 'Files',
  audio: 'Audio',
  contacts: 'Contacts',
  link: 'Links',
  client: 'Clients',
  operator: 'Operators',

  chat: {
    new: 'New chats',
    main: 'Main chats',
    done: 'Done chats',
  },
};
