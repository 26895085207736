<template>
  <div class="scroll-down-button"></div>
</template>

<script lang='ts' setup>
</script>

<style lang='scss' scoped>
.scroll-down-button {
  @extend .rounded--s;
  position: absolute;
  top: -3.25rem;
  right: 1rem;
  @include icon($arrow-down--white, 2.5rem, 1.5rem);
  background-color: $primary;
  z-index: 100;
}
</style>
