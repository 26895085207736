import axios from "axios";

export const useDownload = () => {
  const downloadFile = (url: string, fileName: string, handler?: () => void | undefined) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      handler && handler();
    });
  };

  return { downloadFile };
};
