export const buttons = {
  login: 'Увійти',
  open_location: 'Відкрити розташування',
  continue: 'Продовжити',
  switch_operator: 'Змінити оператора',
  end_chat: 'Завершити чат',
  stay_in_chat: 'Залишитись в чаті',
  submit: 'Підтвердити',
  cancel: 'Скасувати',
  back: 'Назад',
  save_changes: 'Зберегти зміни',
  redirect: 'Переадресувати',
  select_all: 'Обрати всі',
  save: 'Зберегти',
  apply_color: 'Змінити колір',
  next: 'Далі',
  create: 'Створити',
  add_member: 'Додати учасника',
  go_home: 'Повернутися на головну',
  reset: 'Скинути пароль',
};
