import { Message, MessageFile } from '@/types/Message';
import { useApi } from '@/composable/useApi';

export const useMedia = () => {
  const { getApiUrl } = useApi();

  const getMessageAttachment = (message: Message): MessageFile => message.attachments?.[0] ?? {} as MessageFile;

  const getAttachmentUrl = (attachment: MessageFile) => {
    if (attachment?.loadedSrc) {
      return attachment.loadedSrc;
    }

    let url = !['video', 'document2'].includes(attachment.type) ? attachment?.previewUrl : null;
    url = url ?? attachment?.url ?? '';
    return getApiUrl() + url;
  };

  const getMessageMediaUrl = (message: Message) => getAttachmentUrl(getMessageAttachment(message));

  return { getMessageAttachment, getAttachmentUrl, getMessageMediaUrl };
};
