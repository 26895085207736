export const messages = {
  end_chat: {
    question: 'Ви впевнені, що хочете завершити цей чат?',
    success: 'Чат успішно завершено',
    error: 'Не вдалось завершити чат. Помилка: {error}',
  },

  save_note: {
    success: 'Нотатка була успішно збережена',
    error: 'Не вдалось зберегти нотатку. Помилка: {error}',
  },

  redirect: {
    question: 'Будь ласка, вкажіть причину переадресації чату:',
    success: 'Чат було успішно переадресовано',
    error: 'Не вдалось переадресувати чат. Помилка: {error}',
  },

  password_change: {
    success: 'Пароль було успішно змінено',
    error: 'Не вдалось змінити пароль. Помилка: {error}',
  },

  save_changes: {
    success: 'Зміни успішно збережені',
    error: 'Не вдалось зберегти зміни. Помилка: {error}',
  },

  delete_chat: {
    question: 'Ви впевнені, що хочете видалити цей чат?',
    success: 'Чат було успішно видалено',
    error: 'Не вдалось видалити чат. Помилка: {error}',
  },

  delete_several_chats: {
    question: 'Ви впевнені, що хочете видалити чати <span class="info">{names}</span> і всі їх повідомлення для всіх учасників?',
    success: 'Чати {names} було успішно видалено',
    error: 'Не вдалось видалити чати. Помилка: {error}',
  },

  leave_group: {
    question: 'Ви впевнені, що хочете залишити групу?',
    success: 'Ви успішно покинули групу',
    error: 'Не вдалось покинути групу. Помилка: {error}',
  },

  delete_group: {
    title: 'Видалити для всіх',
    subtitle: 'Ви впевнені, що хочете видалити чат і всі його повідомлення для всіх учасників?',
    success: 'Групу було успішно видалено',
    error: 'Не вдалось видалити групу. Помилка: {error}',
  },

  save_file: {
    success: 'Файл було успішно збережено',
    error: 'Не вдалось зберегти файл. Помилка: {error}',
  },

  delete_media_image: {
    question: 'Ви впевнені, що хочете видалити це фото?',
    success: 'Фото було успішно видалене',
    error: 'Не вдалось видалити фото. Помилка: {error}',
  },

  delete_media_video: {
    question: 'Ви впевнені, що хочете видалити це відео?',
    success: 'Відео було успішно видалене',
    error: 'Не вдалось видалити відео. Помилка: {error}',
  },

  update_operator: {
    error: 'Не вдалось оновити інформацію про оператора. Помилка: {error}',
  },

  switch: {
    question: 'Ви впевнені, що хочете вести цей чат?',
    success: 'Успіх. Тепер Ви ведете цей чат',
    error: 'Не вдалось переключити чат. Помилка: {error}',
  },

  delete_message: {
    question: 'Ви впевнені, що хочете видалити це повідомлення?',
    success: 'Повідомлення було успішно видалено',
    error: 'Не вдалось видалити повідомлення. Помилка: {error}',
  },

  quick_answer: {
    success: 'Швидка відповідь була успішно створена',
    error: 'Не вдалось створити швидку відповідь. Помилка: {error}',
  },

  delete_quick_answer: {
    question: 'Ви впевнені, що хочете видалити цю швидку відповідь?',
    success: 'Швидка відповідь була успішно видалена',
    error: 'Не вдалось видалити швидку відповідь. Помилка: {error}',
  },

  create_chat: {
    error: 'Не вдалося створити чат. Помилка: {error}',
  },

  edit_message: {
    error: 'Не вдалось відредагувати повідомлення. Помилка: {error}',
  },

  forward: {
    success: 'Повідомлення було успішно надіслано до',
    error: 'Не вдалось переслати повідомлення. Помилка: {error}',
  },

  forgot_pass: {
    success: 'Лист з підтвердженням було надіслано на вказану пошту. Перевірте Вашу скриньку',
  }
};
